module.exports = {
    /*
        toggle display of list view item content
        on interaction with relevant button elements
    */
    init: () => {
        // get all tag list item elements
        const listItems = Array.from(document.getElementsByClassName("list_view__item"));
        // if there are tag list item elements
        if (listItems) {
            /*
                expand and contract tag list item content on
                user interaction with tag list item's button
            */
            (() => {
                // for each of these button elements
                listItems.forEach((listItem) => {
                    // get the corresponding `button` element which toggles view of the content
                    const buttonElement  = listItem.querySelector(".list_view__expand_button");
                    // get the corresponding `button` element's text content
                    const buttonText     = buttonElement.querySelector(".list_view__expand_button__label");
                    // listen for click on the button element
                    buttonElement.onclick = () => {
                        // on click, toggle the visible state of the corresponding
                        // content `div` element, and change it's text
                        if (!listItem.hasAttribute("open")) {
                            listItem.setAttribute("open", "");
                            buttonText.innerText = "Collapse";
                        } else {
                            listItem.removeAttribute("open");
                            buttonText.innerText = "Expand";
                        }
                    };
                });
            })();
            /*
                collapse all tag list items' content on
                user interaction with collapse all button
            */
            (() => {
                // get collapse all button
                const collapseAllButton = document.querySelector("#list_view__collapse_all");
                // if there is a collapse all button
                if (collapseAllButton) {
                    // on click of collapse all button,
                    collapseAllButton.onclick = () => {
                        // loop over list items
                        listItems.forEach((listItem) => {
                            // check whether they have an open attribute
                            if (listItem.hasAttribute("open")) {
                                // if so, remove the open attribute
                                listItem.removeAttribute("open");
                                // get the text of the visibility toggle button
                                const buttonText = listItem.querySelector(".list_view__expand_button__label");
                                // and change the text to `expand`
                                buttonText.innerText = "Expand";
                            }
                        });
                    };
                }
            })();
            /*
                expand all tag list items' content on
                user interaction with expand all button
            */
            (() => {
                // get expand all button
                const expandAllButton = document.querySelector("#list_view__expand_all");
                // if there is an expand all button
                if (expandAllButton) {
                    // on click of expand all button
                    expandAllButton.onclick = () => {
                        // loop over list items
                        listItems.forEach((listItem) => {
                            // check whether listItem has an open attribute
                            if (!listItem.hasAttribute("open")) {
                                // if not, add the open attribute
                                listItem.setAttribute("open", "");
                                // get the text of the visibility toggle button
                                const buttonText = listItem.querySelector(".list_view__expand_button__label");
                                // and change the text to `collapse`
                                buttonText.innerText = "Collapse";
                            }
                        });
                    };
                }
            })();
        }
    },
};
