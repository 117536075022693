"use strict";
/*
    polyfill to enable us to use forEach on node lists in IE11
*/
const ForEachPolyfill = require("./modules/foreach_polyfill");
ForEachPolyfill.init();
/*
    interpolate between minimum ratio & maximum ratio, based on current viewport width
*/
const TypeRatioInterpolation = require("./modules/type_ratio_interpolation");
TypeRatioInterpolation.init();
/*
    allow enchanced focus detection
*/
const KeyboardFocus = require("./modules/keyboard_focus");
KeyboardFocus.init();
/*
    provide an accessible global navigation
*/
const GlobalNavigation = require("./modules/global_navigation");
GlobalNavigation.init();
/*
    toggle display of list view item content
    on interaction with relevant button elements
*/
const ListViewItemToggle = require("./modules/list_view_item_toggle");
ListViewItemToggle.init();
